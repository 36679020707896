import React from 'react';
import { connect } from 'react-redux';
import { Dialog, StaticFormGroup, ListSelector, RadioButtonGroup } from 'tyb';
// import { reduxForm } from 'redux-form';
const { isvUserEnterprisesList, isvUserAllocation } = iceStarkData.store.get('commonAction')?.isvUser;
const { loadEnterprisesSelections } = iceStarkData.store.get('commonAction')?.enterprises;

import '../index.scss';


/* 
   isv分配 企业
 */
@connect(
    state => ({
        enterprisesSelectionsList: state.enterprises.enterprisesSelectionsList,
        isvUserEnterprisesList: state.isvUser.isvUserEnterprisesList,
        isvUserModifyLoaded: state.isvUser.isvUserModifyLoaded,
    }),
    {
        loadEnterprisesSelections: loadEnterprisesSelections.REQUEST,
        getIsvUserEnterprisesList: isvUserEnterprisesList.REQUEST,
        isvUserAllocation: isvUserAllocation.REQUEST,
    }
)
export default class AllocationISVUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 'all',
            eseIds: [],
        };
        this.list = [
            {
                title: '所有企业',
                text: '所有企业',
                value: 'all'
            },
            {
                title: '分配部分企业',
                text: '分配部分企业',
                value: 'section'
            },
        ];
    }

    componentDidMount() {
        this.props.loadEnterprisesSelections({ status: 'ENABLE' });
    }

    componentDidUpdate(preProps) {
        if (this.props.userId && this.props.userId !== preProps.userId) {
            this.props.getIsvUserEnterprisesList({ userId: this.props.userId });
        }

        // 初始化分配选中数据
        if (this.props.isvUserEnterprisesList !== preProps.isvUserEnterprisesList) {
            this.setState({
                currentTab: !this.props.isvUserEnterprisesList.length || this.props.isvUserEnterprisesList.includes(-1) ? 'all' : 'section',
                eseIds: this.props.isvUserEnterprisesList.includes(-1) ? [] : this.props.isvUserEnterprisesList,
            });
        }

        if ((/2\d{2}$/.test(this.props.isvUserModifyLoaded) && this.props.isvUserModifyLoaded !== preProps.isvUserModifyLoaded)) {
            this.props.handleVisible('', {}, true);
        }
    }

    // 提交
    handleSubmit = () => {
        const { currentTab, eseIds } = this.state;
        this.props.isvUserAllocation({ userId: this.props.userId, eseIds: currentTab == 'all' ? [-1] : eseIds });
    }

    render() {
        const { visible, handleVisible, enterprisesSelectionsList } = this.props;
        const { currentTab, eseIds } = this.state;
        return (
            <Dialog
                className="AllocationISVUserForm list-selector-dialog"
                title="分配"
                visible={visible == 'add'}
                onCancel={handleVisible}
                onOk={this.handleSubmit}
            >
                <div>
                    <RadioButtonGroup
                        value={currentTab}
                        list={this.list}
                        onChange={(currentTab) => { this.setState({ currentTab }); }}
                    />
                    <StaticFormGroup></StaticFormGroup>
                    <div className={currentTab}>
                        <ListSelector
                            value={eseIds}
                            list={enterprisesSelectionsList}
                            prepareItemTextFn={(item) => {
                                return <span>{item.text}</span>
                            }}
                            selectedItemTextFn={(item) => {
                                return <span>{item.text}</span>
                            }}
                            onChange={(eseIds) => {
                                this.setState({ eseIds })
                            }}
                        />
                    </div>
                </div>
            </Dialog >
        )
    }
}

// const validate = (values, props) => {
//     const errors = {};


//     return errors;
// }

// export default reduxForm({
//     form: 'AllocationISVUserForm',
//     initialValues: {

//     },
//     validate
// })(AllocationISVUser)



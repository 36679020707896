import React from 'react';
import { connect } from 'react-redux';
import { Button, SearchInput, DataTable, Pager, PopEditor } from 'tyb';
import queryString from 'query-string';
const { isvUserEnterprises, isvUserEnterprisesList, isvUserEnterprisesRemove } = iceStarkData.store.get('commonAction')?.isvUser;
import AllocationISVUser from './Components/AllocationISVUser';
import AssignRoles from './Components/AssignRoles';

import './index.scss';
/* 
   isv分配 企业列表
 */
@connect(
    state => ({
        permissionIds: state.users.permissionIds||[],
        loading: state.isvUser.loading,
        isvUserEnterprises: state.isvUser.isvUserEnterprises,
        isvUserEnterprisesRemoveLoaded: state.isvUser.isvUserEnterprisesRemoveLoaded,
    }),
    {
        getIsvUserEnterprises: isvUserEnterprises.REQUEST,
        getIsvUserEnterprisesList: isvUserEnterprisesList.REQUEST,
        isvUserEnterprisesRemove: isvUserEnterprisesRemove.REQUEST,
    })
export default class EnterpriseAllocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            linkType: [],
        };
        this.params = { page: 0, size: 20 };
        this.status = { ENABLE: '禁用', DISABLE: '启用' };
        this.columns = [
            {
                key: 'eseName',
                dataIndex: 'eseName',
                title: '企业名称',
            },
            {
                key: 'operate',
                dataIndex: 'operate',
                title: '操作',
                render: (val, record) => (
                    <div className="operate">
                        {this.props.permissionIds.includes('ws.isvUser.allocation') && <a href="javascript:;" onClick={() => { this.setState({ operateVisible: `${record.eseId}_visible` }) }}>移除</a>}
                        {this.props.permissionIds.includes('ws.isvUser.allocation') && <a href="javascript:;" onClick={() => { this.handleVisible('role', record) }}>分配角色</a>}
                        {this.state.operateVisible == `${record.eseId}_visible` &&
                            <div className="confirm-popEditor">
                                <PopEditor
                                    onOk={() => { this.handleOperate(record) }}
                                    onCancel={() => { this.setState({ operateVisible: '' }) }}
                                >
                                    <p>
                                        <strong>移除当前企业？</strong>
                                        <br></br>
                                        确认移除当前企业
                                    </p>
                                </PopEditor>
                            </div>
                        }
                    </div>)
            },
        ]
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        const { userId } = query || {};
        if (userId) {
            this.params.userId = userId;
            this.setState({ userId });
            this.props.getIsvUserEnterprises(this.params);
        }
    }

    componentDidUpdate(preProps) {
        if ((/2\d{2}$/.test(this.props.isvUserEnterprisesRemoveLoaded) && this.props.isvUserEnterprisesRemoveLoaded !== preProps.isvUserEnterprisesRemoveLoaded)) {
            this.props.getIsvUserEnterprises(this.params);
            this.props.getIsvUserEnterprisesList({ userId: this.params.userId });
        }
    }

    search = () => {
        this.setState({
            page: 0,
        });
        this.params.page = 0;
        this.props.getIsvUserEnterprises(this.params);
    }

    onPageChange = (val) => {
        this.setState({ page: val });
        this.params.page = val;
        this.props.getIsvUserEnterprises(this.params);
    }

    onPerPageChange = (val) => {
        this.setState({ perPageCount: val });
        this.params.size = val;
        this.params.page = 0;
        this.props.getIsvUserEnterprises(this.params);
    }

    handleVisible = (type, record = {}, refulsh) => {
        this.setState({ visible: type, record });
        if (refulsh) {
            this.props.getIsvUserEnterprises(this.params);
        }
    }

    handleOperate = (record) => {
        // const { isvUserEnterprises = {} } = this.props;
        // const { content } = isvUserEnterprises;
        // const eseIds = [];
        // content.forEach(val => {
        //     if (val.eseId !== record.eseId) {
        //         eseIds.push(val.eseId);
        //     }
        // });
        this.props.isvUserEnterprisesRemove({ userId: this.params.userId, eseIds: [record.eseId] });
    }

    render() {
        const { keywords, page, perPageCount = 20, visible, record, userId } = this.state;
        const { permissionIds, loading, isvUserEnterprises = {} } = this.props;
        const { content, totalElements } = isvUserEnterprises;
        return (
            <React.Fragment>
                <div className="EnterpriseAllocation">
                    <div className="search-bar">
                        {permissionIds.includes('ws.isvUser.allocation') && <Button onClick={() => { this.handleVisible('add'); }}>分配</Button>}
                        <div className="search-input-block">
                            <SearchInput
                                onChange={(val) => { this.params.keywords = val; this.setState({ keywords: val }) }}
                                onSearch={this.search}
                                value={keywords}
                                placeholder="搜索企业名称"
                            />
                        </div>
                    </div>

                    <div className="container">
                        <DataTable
                            columns={this.columns}
                            data={content}
                            rowKey="id"
                            loading={loading}
                            rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
                        />
                        <Pager
                            total={totalElements}
                            current={page}
                            perPageCountList={[10, 20, 50]}
                            perPageCount={perPageCount}
                            onPageChange={this.onPageChange}
                            onPerPageChange={this.onPerPageChange}
                        />
                    </div>
                </div>
                <AllocationISVUser
                    record={record}
                    userId={userId}
                    visible={visible}
                    handleVisible={this.handleVisible}
                />
                <AssignRoles
                    record={record}
                    userId={userId}
                    visible={visible}
                    handleVisible={this.handleVisible}
                />
            </React.Fragment>
        )
    }
}
